.mainLayout {
  min-height: 100vh;
  background: var(--gray-1);
  position: relative;
  padding-bottom: 48px !important;

  .content {
    padding-top: 120px;
    padding-left: 408px;
    padding-right: 448px;
    transition: all 0.3s ease-in-out;
    z-index: 2;
  }
}

.mainLayoutFull {
  .content {
    padding-left: 184px;
    transition: all 0.3s ease-in-out;
  }
}

.mainLayoutCompact {
  padding: 0;
}

.adminLayout {
  padding: 0;

  .content {
    padding-right: 30px !important;
  }
}

.mainLayoutNoRightSidebar {
  .content {
    padding-right: 48px;
  }
}

@media screen and (max-width: 1500px) {
  .mainLayout {
    .content {
      padding-left: 390px;
      padding-right: 30px;
    }
  }

  .mainLayoutFull {
    .content {
      padding-left: 184px;
      transition: all 0.3s ease-in-out;
    }
  }
}

@media screen and (max-width: 1024px) {
  .mainLayout {
    min-height: 100vh;
    background: var(--gray-1);
    position: relative;
    padding-bottom: 48px !important;

    .content {
      padding-top: 80px;
      padding-left: 16px;
      padding-right: 16px;
    }
  }

  .mainLayoutFull {
    .content {
      padding-left: 16px;
      padding-right: 16px;
    }
  }
}
