.content {
  p,
  ul li,
  ol li {
    font-size: 14px;
    line-height: 24px;
    color: var(--gray-4);
    margin-bottom: 8px;
    font-weight: 400;
  }

  strong,
  b {
    font-weight: 600;
  }

  h2,
  h3 {
    margin-top: 24px;
    margin-bottom: 16px;
    font-size: 22px;
    line-height: 32px;
    color: var(--gray-4);
    font-weight: 600;
  }

  h3 {
    font-size: 18px;
    line-height: 28px;
  }
}
