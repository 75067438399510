html body {
  width: 100% !important;
}

html,
body,
p,
ul li,
ol li {
  color: var(--gray-4);
}

h1,
h2,
h3,
h4,
h5 {
  color: var(--gray-4);
}

a {
  color: var(--primary);
  outline: none;
  text-decoration: none;
  transition: all 0.3s ease-in-out;

  &:hover {
    color: var(--primary-light);
    transition: all 0.3s ease-in-out;
  }
}

.cursor-pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.span-coma {
  span {
    &:after {
      content: ', ';
    }

    &:last-child {
      &:after {
        content: '';
      }
    }
  }
}

.default-color {
  color: var(--gray-4) !important;
}

.muted-color {
  color: var(--gray-5) !important;
}

.danger-color {
  color: var(--danger) !important;
}

.primary-color {
  color: var(--primary) !important;
}

.warning-color {
  color: var(--warning) !important;
}

.full-page-modal__container {
  display: block;
  width: 100%;
  max-width: 1870px;
  padding: 0 15px;
  margin: 0 auto;

  &-small {
    max-width: 970px;
  }
}

.ant-message .anticon {
  vertical-align: 1px;
}

.mobile-hidden {
  visibility: visible;
  width: auto;
}

@media screen and (max-width: 640px) {
  .mobile-hidden {
    visibility: hidden !important;
    margin: 0 !important;
    padding: 0 !important;
    width: 0 !important;
  }
}
