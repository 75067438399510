.ant-form-item {
  margin-bottom: 24px;

  &:last-child {
    margin-bottom: 0;
  }
}

.ant-input {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--gray-4);
  height: auto;
  border: 1px solid transparent;
  background-color: var(--gray-2);
  padding: 12px 16px;
  border-radius: 8px;
}

.stripe-input-wrapper {
  border: 1px solid transparent;
  background-color: var(--gray-2);
  padding: 12px 16px;
  border-radius: 8px;
}

.payment-method-select {
  .ant-select-selection-item {
    height: 30px;
    margin-top: -7px;
  }
}

.ant-input-number {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--gray-4);
  background-color: var(--gray-2);
  width: 100%;
  padding: 10px 16px;
  border-radius: 8px;
  border: 1px solid transparent;
}

.ant-input-number:hover {
  border-color: var(--primary-light);
}

.ant-input-number:focus,
.ant-input-number-focused {
  border-color: var(--primary);
  box-shadow: none;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: var(--gray-2);
  border: 1px solid transparent;
  border-radius: 8px;
}

.ant-input.input {
  background: var(--gray-2);
}

.ant-select {
  color: var(--gray-4);
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
}

.ant-select-single:not(.ant-select-customize-input) .ant-select-selector {
  width: 100%;
  height: auto;
  padding: 10px 16px;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  position: relative;
  background-color: var(--gray-2);
  border: 1px solid transparent;
  border-radius: 8px;
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(
    .ant-select-customize-input
  )
  .ant-select-selector {
  border-color: var(--primary);
  box-shadow: none;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--primary-light);
}

.ant-form label {
  color: var(--gray-4) !important;
}

.ant-input:hover {
  border-color: var(--border-color);
}

.ant-input:focus {
  border-color: var(--primary);
  box-shadow: none;
  outline: none;
}

.ant-input-affix-wrapper {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--gray-4);
  height: auto;
  border: 1px solid transparent;
  background-color: var(--gray-2);
  border-radius: 8px;
  padding: 6px 16px;
  overflow: hidden;

  .input__clear,
  .ant-input {
    width: 100%;
    padding: 0;
    border: 1px solid transparent;
    margin: 0;
    border-radius: 0;
    box-shadow: none;
    background-color: transparent !important;
    font-weight: 400;
    font-size: 16px;
    line-height: 22px;
    outline: none !important;
  }

  .ant-input-clear-icon {
    .anticon {
      vertical-align: 0;
    }
  }
}

.ant-select-multiple .ant-select-selector {
  padding-top: 9px;
  padding-bottom: 9px;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background: var(--border-color);
}

.select-search-ls
  .ant-select-selection-search
  .ant-select-selection-search-input {
  padding: 12px 0 !important;
  height: 100% !important;
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled)
  .ant-select-item-option-state {
  color: var(--primary);
}

.anticon.ant-input-clear-icon,
.ant-input-clear-icon {
  color: var(--gray-3);
}

.ant-input-affix-wrapper:not(.ant-input-affix-wrapper-disabled):hover {
  border-color: var(--border-color);
}

.ant-input-affix-wrapper:focus,
.ant-input-affix-wrapper-focused {
  border-color: var(--primary) !important;
  box-shadow: none;
}

[data-theme='dark'] {
  .ant-input.input,
  .ant-input-affix-wrapper {
    background-color: var(--gray-1);
  }
}

.micro-tasks-qty {
  .ant-radio-button-wrapper {
    margin-right: 12px;
    margin-bottom: 12px;
    position: relative;
    display: inline-block;
    height: auto;
    padding: 8px 16px;
    color: var(--gray-4);
    font-weight: 400;
    font-size: 16px;
    line-height: 150%;
    background: var(--gray-2);
    border: 2px solid var(--gray-2) !important;
    border-radius: 8px;
    cursor: pointer;
    transition: color 0.3s, background 0.3s, border-color 0.3s, box-shadow 0.3s;
  }

  .ant-radio-button-wrapper:not(:first-child)::before {
    display: none;
  }

  .ant-radio-button-wrapper-checked {
    color: var(--primary);
    background: var(--white);
    border: 2px solid var(--primary) !important;
  }

  .ant-radio-button-wrapper-checked:not(
      .ant-radio-button-wrapper-disabled
    ):hover {
    color: var(--primary);
    border-color: var(--primary);
  }
}
