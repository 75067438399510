:root {
  --primary: #3e914a;
  --primary-lighter: #65cb73;
  --primary-light: rgb(61, 152, 74);

  --danger: #f1416c;
  --danger-light: rgba(241, 65, 108, 0.1);

  --warning: #ffc700;
  --warning-light: rgba(255, 199, 0, 0.2);

  --gray-0: #ffffff;
  --gray-1: #f9fafb;
  --gray-2: #f2f3f5;
  --gray-3: #808080;
  --gray-4: #242429;
  --gray-5: #777777;

  --right-sidebar: #1e1e2d;
  --right-sidebar-highlight: #161621;
  --text-on-dark: #ffffff;
  --right-sidebar-primary: #30914a;
  --right-sidebar-secondary: #6f914a;

  --white: #ffffff;
  --white-2: #ffffff;

  --border-color: rgba(62, 145, 74, 0.2);
  --border-color2: rgba(62, 145, 74, 0.1);
  --border-color3: #e0e0e0;
}

[data-theme='dark'] {
  --primary: #3e914a;
  --primary-light: rgb(61, 152, 74);

  --gray-0: #1e1e2d;
  --gray-1: #151521;
  --gray-2: #1e1e2d;
  --gray-3: #9bb888;
  --gray-4: #ffffff;
  --gray-5: #9bb888;

  --right-sidebar: #1e1e2d;
  --right-sidebar-highlight: #161621;
  --text-on-dark: #ffffff;
  --right-sidebar-primary: #30914a;
  --right-sidebar-secondary: #6f914a;

  --white: #ffffff;
  --white-2: #1e1e2d;

  --border-color: rgba(62, 145, 74, 0.2);
  --border-color2: rgba(62, 145, 74, 0.1);
  --border-color3: #323248;
}
