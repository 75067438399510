.advertiserHeader {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  padding-top: 48px;
  padding-right: 448px;
  margin-bottom: 32px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s ease-in-out;
  z-index: 3;

  .search {
    position: relative;

    .input {
      background: var(--gray-0);
      border: 1px solid var(--border-color);
      border-radius: 8px;
      padding: 12px 12px 12px 38px;
      font-weight: 500;
      font-size: 15px;
      line-height: 24px;

      &:hover,
      &:focus {
        outline: none;
        border: 1px solid var(--primary);
      }
    }

    .icon {
      position: absolute;
      top: 52%;
      transform: translateY(-50%);
      left: 12px;
      line-height: 1;
    }
  }

  .right {
    margin-right: 48px;

    .sidebarToggle {
      display: none;

      button {
        padding: 0;
        background: transparent;
        border: none;
        cursor: pointer;

        svg {
          width: 28px;
          height: 28px;
          margin-top: -4px;
        }
      }
    }

    .marketersHeadMenu {
      padding: 0;
      margin: 0;
      list-style: none;
      display: flex;
      align-items: center;
      justify-content: flex-start;

      li {
        margin-right: 24px;

        &:last-child {
          margin-right: 0;
        }

        a {
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--gray-4);
          transition: all 0.3s ease-in-out;

          &:hover {
            color: var(--primary-light);
            transition: all 0.3s ease-in-out;
          }
        }
      }
    }
  }

  .left {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .createCampaign {
      margin-right: 20px;

      a {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 12px 8px;
        border: 1px solid var(--border-color);
        border-radius: 8px;
        transition: all 0.3s ease-in-out;

        .icon {
          margin-top: -2px;
          margin-right: 12px;
        }

        .placeholder {
          font-weight: 500;
          font-size: 14px;
          line-height: 20px;
          color: var(--primary);
        }

        &:hover {
          background: var(--border-color);
          border-color: transparent;
          transition: all 0.3s ease-in-out;
        }
      }

      .noBorder {
        border-color: transparent;
      }
    }

    .balance {
      margin-right: 20px;

      span {
        font-weight: 400;
        font-size: 14px;
        line-height: 145%;
        color: var(--gray-3);

        strong {
          padding-left: 4px;
          font-weight: 600;
          color: var(--primary);
        }
      }
    }

    .headerSearch {
      margin-right: 24px;
    }

    .headerIcons {
      display: flex;
      justify-content: flex-start;
      align-items: center;

      .iconItem {
        margin-right: 20px;

        button {
          padding: 0;
          background: transparent;
          border: none;
          cursor: pointer;
          transition: all 0.3s ease-in-out;

          svg {
            path {
              stroke: var(--gray-5);
              transition: all 0.3s ease-in-out;
            }
          }

          &:hover {
            svg path {
              stroke: var(--primary);
              transition: all 0.3s ease-in-out;
            }
          }
        }

        &:last-child {
          margin-right: 0;
        }
      }

      .iconItemLaptop {
        display: none;
      }
    }
  }
}

.advertiserHeaderFull {
  padding-left: 392px;
  transition: all 0.3s ease-in-out;
}

.advertiserHeaderNoRightSidebar {
  padding-right: 48px;
}

.advertiserHeaderCompact {
  padding-left: 184px;
  transition: all 0.3s ease-in-out;
}

.advertiserHeaderBG {
  padding-top: 16px;
  padding-bottom: 16px;
  background: var(--gray-0);
  transition: all 0.3s ease-in-out;
}

@media screen and (max-width: 1500px) {
  .advertiserHeader {
    padding-right: 32px;

    .left {
      .headerIcons {
        .iconItemLaptop {
          display: block;
        }
      }
    }
  }

  .advertiserHeader.advertiserHeaderNoRightSidebar {
    padding-right: 448px;
  }
}

@media (min-width: 1024px) and (max-width: 1500px) {
  .advertiserHeader {
    .right {
      margin-right: 12px;

      .search {
        input {
          width: 100%;
          max-width: 300px;
        }
      }
    }

    .left {
      .createCampaign {
        padding: 6px;

        .placeholder {
          font-size: 12px;
          line-height: 16px;
        }
      }
    }
  }
}

.advertiserAdminHeader {
  padding-right: 30px;
}

@media screen and (max-width: 1024px) {
  .advertiserHeader {
    .left {
      .headerIcons {
        .iconItemLaptop {
          display: none;
        }
      }
    }
  }

  .advertiserHeader {
    padding-top: 24px;
    padding-right: 16px;
    padding-left: 16px !important;
    margin-bottom: 16px;

    .search {
      display: none;
    }

    .right {
      margin-right: 24px;

      .sidebarToggle {
        display: block;
      }

      .marketersHeadMenu {
        display: none;
      }
    }

    .left {
      .createCampaign {
        margin-right: 20px;

        a {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding: 12px 8px;
          border: 1px solid var(--border-color);
          border-radius: 8px;
          transition: all 0.3s ease-in-out;

          .icon {
            margin-top: -2px;
            margin-right: 12px;
          }

          .placeholder {
            font-weight: 500;
            font-size: 14px;
            line-height: 20px;
            color: var(--primary);
          }

          &:hover {
            background: var(--border-color);
            border-color: transparent;
            transition: all 0.3s ease-in-out;
          }
        }

        .noBorder {
          border-color: transparent;
        }
      }

      .headerIcons {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .iconItem {
          margin-right: 20px;

          button {
            padding: 0;
            background: transparent;
            border: none;
            cursor: pointer;
            transition: all 0.3s ease-in-out;
          }

          &:last-child {
            margin-right: 0;
          }
        }

        .iconItemLaptop {
          display: none;
        }
      }
    }
  }

  .advertiserHeaderFull {
    padding-left: 0;
  }

  .advertiserHeaderCompact {
    padding-left: 0;
  }
}
