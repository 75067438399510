.rightSidebar {
  position: fixed;
  top: 0;
  right: 0;
  height: 100%;
  width: 400px;
  padding: 48px 32px;
  background-color: var(--right-sidebar);
  // overflow: hidden;
  z-index: 99999;
  transition: all 0.3s ease-in-out;

  .toggle {
    position: absolute;
    left: -32px;
    top: 32px;
    // display: none !important;
    transition: all 0.3s ease-in-out;

    button {
      padding: 0;
      margin: 0;
      box-shadow: none;
      border: 0;
      background: transparent;

      svg {
        height: 40px;
        width: auto;
      }
    }
  }

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 52px;

    .headerTab {
      margin-right: 12px;
      border-radius: 12px;
      overflow: hidden;

      &:last-child {
        margin-right: 0;
      }

      button {
        padding: 28px 22px;
        margin: 0;
        border: none;
        background: var(--right-sidebar-highlight);
        box-shadow: none;

        svg g {
          opacity: 0.5;
          transition: all 0.3s ease-in-out;
        }

        &:hover svg g {
          opacity: 1;
          transition: all 0.3s ease-in-out;
        }
      }

      .active svg g {
        opacity: 1;
      }
    }
  }

  .content {
    max-height: calc(100vh - 200px);
    padding-bottom: 48px;
    overflow: auto;

    h1 {
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      margin-top: 0;
      margin-bottom: 24px;
      color: var(--text-on-dark);
    }

    h4 {
      font-weight: 400;
      font-size: 16px;
      line-height: 28px;
      margin-top: 0;
      margin-bottom: 24px;
      color: var(--text-on-dark);
    }
  }

  /* Hide scrollbar for Chrome, Safari and Opera */
  .content::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .content {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

.rightSidebarOpen {
  right: 0;
  transition: all 0.3s ease-in-out;
}

.rightSidebarHidden {
  transition: all 0.3s ease-in-out;
}

@media (min-width: 1501px) {
  .rightSidebarHidden {
    right: -400px;

    .toggle {
      display: block;
    }
  }
}

.card1 {
  background: var(--right-sidebar-highlight);
  border-radius: 8px;
  padding: 12px 16px;
  margin-bottom: 16px;

  &:last-child {
    margin-bottom: 0;
  }

  .cardIcon {
    margin-right: 16px;
  }

  .cardTitle {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-on-dark);
  }

  .cardText {
    margin-bottom: 0;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--right-sidebar-secondary);
  }

  .affiliateWrapper {
    padding: 8px 16px;
    background: var(--right-sidebar-highlight);
    border-radius: 8px;

    p {
      font-weight: 400;
      font-size: 14px;
      line-height: 20px;
      color: var(--right-sidebar-secondary);
      margin-bottom: 0;
    }

    h3 {
      margin-top: 0;
      margin-bottom: 0;
      font-weight: 400;
      font-size: 16px;
      line-height: 24px;
      color: var(--text-on-dark);
    }
  }

  .affiliateButton {
    padding: 18px;
    margin: 0;
    border: none;
    background: var(--primary);
    color: var(--text-on-dark);
    border-radius: 8px;
  }
}

.card2 {
  .cardTitle {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: var(--text-on-dark);
  }
}

.card1Inverted {
  @extend .card1;
  background: transparent;
  border: 1px solid var(--right-sidebar-primary);
}

.card1InvertedColorized {
  .cardTitle {
    color: var(--primary);
  }
}

.cardStats {
  border: 1px dashed var(--right-sidebar-secondary);
  border-radius: 12px;
  padding: 12px 16px;
  margin-bottom: 16px;

  h3 {
    min-height: 40px;
    margin-top: 0;
    margin-bottom: 8px;
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: var(--right-sidebar-secondary);
  }

  h2 {
    margin-top: 0;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 18px;
    line-height: 28px;
    color: var(--text-on-dark);
  }
}

.socialLinks {
  a {
    .socialLink {
      padding: 16px;
      text-align: center;
      background-color: var(--right-sidebar-highlight);
      border-radius: 8px;
      transition: all 0.3s ease-in-out;

      svg {
        width: 24px;
        height: 24px;

        path {
          transition: all 0.3s ease-in-out;
        }
      }
    }

    &:hover .socialLink {
      background-color: var(--primary);
      transition: all 0.3s ease-in-out;

      svg path {
        stroke: var(--right-sidebar-highlight);
        transition: all 0.3s ease-in-out;
      }
    }
  }
}

@media screen and (max-width: 1500px) {
  .rightSidebar {
    right: -400px;
    transition: all 0.3s ease-in-out;
    overflow: visible;

    .toggle {
      display: block;
      top: 98px;
    }
  }

  .rightSidebarOpen {
    right: 0;
    transition: all 0.3s ease-in-out;
  }
}

@media screen and (max-width: 1024px) {
  .rightSidebar {
    right: -100%;
    height: 100%;
    width: 100%;
    max-width: 420px;
    padding: 32px 24px;

    .toggle {
      top: 56px;
      left: -26px;
    }

    .content {
      max-height: calc(100vh - 200px);
      padding-bottom: 32px;

      h1 {
        font-size: 16px;
        line-height: 28px;
        margin-bottom: 18px;
      }
    }
  }

  .rightSidebarOpen {
    right: 0;

    .toggle {
      position: absolute;
      left: 0;
      top: 8px;
      transform: rotate(180deg);
      transition: all 0.3s ease-in-out;

      button {
        padding: 0;
        margin: 0;
        box-shadow: none;
        border: 0;
        background: transparent;
      }
    }
  }
}
