.leftSidebar {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  z-index: 10;

  .outer {
    height: 100%;
    padding: 48px;

    .inner {
      position: relative;
      height: 100%;
      border-radius: 16px;
      overflow: hidden;
      background: var(--gray-2);

      .header {
        padding: 32px;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo a {
          text-decoration: none;

          img {
            height: 36px;
            width: auto;
          }
        }

        .menuHandler {
          margin-left: 48px;

          button {
            padding: 0;
            margin: 0;
            border: none;
            box-shadow: none;
            background: transparent;
          }
        }
      }

      .sidebarMenu {
        margin-top: 22px;

        > ul {
          padding: 0;
          margin: 0;
          list-style: none;

          > li {
            margin-bottom: 20px;

            > a {
              width: 100%;
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 2px 32px;
              transition: all 0.3s ease-in-out;

              .icon {
                margin-right: 12px;
                line-height: 1;
              }

              .icon[data-type='marketer'] {
                svg {
                  margin-top: -3px;
                }
              }

              .name {
                font-weight: 400;
                font-size: 16px;
                line-height: 150%;
                color: var(--gray-4);
                transition: all 0.3s ease-in-out;
              }

              .arrow {
                margin-left: auto;

                button {
                  padding: 0;
                  margin: 0;
                  box-shadow: none;
                  border: 0;
                  background: transparent;

                  svg {
                    width: 14px;
                    height: 14px;
                    transform: rotate(-90deg);
                  }
                }
              }

              .arrow.arrow__active button svg {
                transform: rotate(90deg);
              }

              &:hover .name {
                color: var(--primary);
              }
            }

            &:last-child {
              margin-bottom: 0;
            }

            > ul {
              height: 0;
              overflow: hidden;
              padding: 0;
              margin: 0;
              list-style: none;
              transition: all 0.3s ease;

              > li {
                margin-bottom: 12px;

                &:first-child {
                  margin-top: 12px;
                }

                &:last-child {
                  margin-bottom: 0;
                }

                > a {
                  font-weight: 400;
                  font-size: 16px;
                  line-height: 150%;
                  padding: 0 32px 0 68px;
                  color: var(--gray-4);
                  transition: all 0.3s ease;

                  &:hover {
                    color: var(--primary);
                  }
                }
              }
            }

            > ul.active {
              height: auto;
              transition: all 0.15s ease-in-out;
            }
          }

          .mobileOnly {
            display: none;
          }
        }
      }

      .sidebarFooter {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        padding: 12px 32px 32px;
        height: auto;

        .footerWrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          user-select: none;
          cursor: pointer;

          .footerLogo {
            margin-right: 12px;

            .logoWrapper {
              position: relative;
              width: 40px;
              height: 40px;
              background: var(--white);
              border-radius: 8px;
              overflow: hidden;

              img,
              span {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
              }

              span {
                font-size: 22px;
                line-height: 1;
                font-weight: 500;
                color: var(--primary);
              }

              img {
                width: 100%;
                height: auto;
              }
            }
          }

          .footerCompanyName {
            margin-right: 12px;

            h3 {
              margin: 0;
              font-weight: 500;
              font-size: 14px;
              line-height: 20px;
              color: var(--gray-4);
            }

            p {
              margin-bottom: 0;
              text-transform: capitalize;
              font-weight: 500;
              font-size: 12px;
              line-height: 20px;
              color: var(--gray-3);

              span {
                color: var(--primary);
              }
            }
          }

          .footerSettingsIcon {
            margin-left: auto;

            button {
              padding: 0;
              margin: 0;
              border: none;
              box-shadow: none;
              background: transparent;
            }
          }
        }

        .footerMenu {
          display: none;
          position: absolute;
          left: 0;
          bottom: 100%;
          width: 312px;
          padding: 0 32px;

          .footerMenuWrapper {
            background: var(--gray-0);
            box-shadow: 0 6px 16px rgba(0, 0, 0, 0.12);
            border-radius: 8px;

            .header {
              display: flex;
              justify-content: flex-start;
              align-items: center;
              padding: 24px;
              border-bottom: 1px solid var(--border-color3);

              .logo {
                margin-right: 12px;

                .logoWrapper {
                  position: relative;
                  width: 40px;
                  height: 40px;
                  background: var(--white);
                  border-radius: 8px;
                  overflow: hidden;

                  img,
                  span {
                    position: absolute;
                    top: 50%;
                    left: 50%;
                    transform: translate(-50%, -50%);
                  }

                  span {
                    font-size: 22px;
                    line-height: 1;
                    font-weight: 500;
                    color: var(--primary);
                  }

                  img {
                    width: 100%;
                    height: auto;
                  }
                }
              }

              .companyName {
                overflow: hidden;

                h3 {
                  margin: 0;
                  font-weight: 500;
                  font-size: 14px;
                  line-height: 20px;
                  color: var(--gray-4);
                }

                p {
                  margin-bottom: 0;
                  text-transform: lowercase;
                  font-weight: 500;
                  font-size: 12px;
                  line-height: 20px;
                  color: var(--gray-3);
                }
              }
            }

            .menu {
              padding: 24px 0;

              ul {
                padding: 0;
                margin: 0;
                list-style: none;

                li {
                  margin-bottom: 12px;

                  &:last-child {
                    margin-bottom: 0;
                  }

                  a {
                    display: block;
                    padding: 2px 24px;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 20px;
                    color: var(--advertiser-text-1);
                    transition: all 0.3s ease-in-out;

                    &:hover {
                      color: var(--advertiser-primary);
                      transition: all 0.3s ease-in-out;
                    }
                  }
                }

                li.divider {
                  width: 100%;
                  height: 1px;
                  background: var(--border-color);
                  margin: 20px 0;
                }
              }
            }
          }
        }

        .footerMenuOpen {
          display: block;
        }
      }
    }
  }
}

.leftSidebarFull {
  width: 408px;
  transition: all 0.15s ease-in-out;
}

.leftSidebarCompact {
  width: 184px;
  transition: all 0.15s ease-in-out;

  .outer {
    .inner {
      overflow: visible;

      .sidebarMenu {
        margin-top: 22px;

        > ul {
          > li {
            position: relative;

            > a {
              position: relative;

              &:hover {
                svg path {
                  fill: var(--primary) !important;
                }
              }
            }
          }
        }
      }

      .header {
        .logo {
          display: none;
        }

        .menuHandler {
          margin-left: 0;
        }
      }

      .sidebarMenu {
        ul {
          li {
            a {
              .icon {
                margin-right: 0;

                svg path {
                  transition: all 0.3s ease-in-out;
                }
              }

              .name {
                display: none;
              }

              &:hover .icon svg path {
                fill: var(--advertiser-primary);
                transition: all 0.3s ease-in-out;
              }
            }

            &:last-child {
              margin-bottom: 0;
            }
          }
        }
      }

      .sidebarFooter {
        padding-left: 24px;
        padding-right: 24px;

        .footerWrapper {
          .footerCompanyName,
          .footerSettingsIcon {
            display: none;
          }
        }

        .footerMenu {
          .footerMenuWrapper {
            .header {
              .logo {
                display: block;
              }
            }
          }
        }
      }
    }
  }
}

@media (min-width: 1100px) {
  .leftSidebarCompact {
    width: 184px;
    transition: all 0.15s ease-in-out;

    .outer {
      .inner {
        overflow: visible;

        .sidebarMenu {
          margin-top: 22px;

          > ul {
            > li {
              position: relative;

              > a {
                position: relative;

                .arrow {
                  display: none;
                }

                &:hover {
                  svg path {
                    fill: var(--primary) !important;
                  }
                }
              }

              > ul {
                position: absolute;
                width: 200px;
                display: none;
                top: 0;
                left: 100%;
                background: var(--gray-2);
                border-top-right-radius: 16px;
                border-bottom-right-radius: 16px;

                > li {
                  margin: 12px 0 !important;

                  > a {
                    padding-left: 32px;
                  }
                }
              }

              &:hover > ul {
                display: block;
                height: auto;
              }
            }
          }
        }

        .sidebarMenu {
          ul {
            li {
              a {
                .icon {
                  margin-right: 0;

                  svg path {
                    transition: all 0.3s ease-in-out;
                  }
                }

                .name {
                  display: none;
                }

                &:hover .icon svg path {
                  fill: var(--advertiser-primary);
                  transition: all 0.3s ease-in-out;
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: 1024px) {
  .leftSidebar {
    left: -100%;

    .outer {
      padding: 12px;

      .inner {
        .header {
          padding: 24px;

          .menuHandler {
            margin-left: 24px;
          }
        }

        .sidebarMenu {
          margin-top: 12px;

          ul {
            li {
              margin-bottom: 16px;

              a {
                padding: 2px 24px;

                .name {
                  font-size: 14px;
                }
              }
            }

            .mobileOnly {
              display: block;
            }
          }
        }
      }
    }
  }

  .leftSidebarFull {
    width: 100%;
    max-width: 375px;
  }

  .leftSidebarCompact {
    left: 0;
    width: 100%;
    max-width: 375px;

    .outer {
      .inner {
        overflow: visible;

        .header {
          .logo {
            display: block;
          }

          .menuHandler {
            margin-left: 24px;
          }
        }

        .sidebarMenu {
          ul {
            li {
              a {
                .icon {
                  margin-right: 12px;

                  svg path {
                    transition: all 0.3s ease-in-out;
                  }
                }

                .name {
                  display: block;
                }

                &:hover .icon svg path {
                  fill: var(--advertiser-primary);
                  transition: all 0.3s ease-in-out;
                }
              }

              &:last-child {
                margin-bottom: 0;
              }
            }
          }
        }

        .sidebarFooter {
          padding-left: 24px;
          padding-right: 24px;

          .footerWrapper {
            .footerCompanyName,
            .footerSettingsIcon {
              display: block;
            }
          }

          .footerMenu {
            .footerMenuWrapper {
              .header {
                .logo {
                  display: block;
                }
              }
            }
          }
        }
      }
    }
  }
}
