.d-block {
  display: block;
}

.d-none {
  display: none;
}

@media (min-width: 1024px) {
  .d-lg-none {
    display: none;
  }

  .d-lg-block {
    display: block;
  }
}
