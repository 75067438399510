.ant-checkbox {
  color: var(--gray-4);
}

.ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--primary);
  border-color: var(--primary);
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner,
.ant-checkbox:hover .ant-checkbox-inner,
.ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--primary);
}

.ant-checkbox-inner {
  border: 1px solid var(--border-color2);
}

.ant-checkbox-checked::after {
  border: 1px solid var(--primary);
}

.ant-checkbox-wrapper,
.ant-checkbox + span {
  color: var(--gray-4);
  font-size: 16px;
  font-weight: 400;
  height: auto;
  line-height: 22px;
}
