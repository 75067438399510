body {
  .videoLibrary {
    margin-bottom: 18px;
    padding-left: 0;
    padding-right: 0;

    &:last-child {
      margin-bottom: 0;
    }

    h3 {
      color: var(--text-on-dark) !important;
      text-align: left;
    }

    p {
      color: var(--right-sidebar-secondary) !important;
      text-align: left;
    }
  }
}
