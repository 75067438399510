.ant-btn {
  font-weight: 400;
  font-size: 16px;
  line-height: 22px;
  color: var(--gray-4);
  height: auto;
  border: 1px solid transparent;
  background: var(--gray-2);
  padding: 12px 16px;
  border-radius: 8px;

  span {
    color: var(--gray-4) !important;
  }

  svg {
    margin-top: -2px;
    margin-right: 6px;
  }

  &:hover {
    border-color: var(--primary);
    color: var(--primary);
  }
}

.btn-md {
  padding: 8px 14px;
}

.btn-sm {
  padding: 6px 12px;
  font-size: 14px;
  line-height: 18px;
}

.btn-w-full {
  width: 100%;
}

.btn-link {
  background: transparent !important;
  color: var(--gray-4) !important;
  border-color: transparent !important;
}

.btn-primary {
  background: var(--primary);
  border-color: var(--primary);
  color: var(--white);

  span {
    color: var(--white) !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--white) !important;
    background: var(--primary-light);
    border-color: var(--primary-light);
  }
}

.btn-primary__light {
  background: var(--border-color2);
  border-color: var(--border-color2);
  color: var(--primary);

  span {
    color: var(--primary) !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--white) !important;
    background: var(--primary);
    border-color: var(--primary);

    span {
      color: var(--white) !important;
    }
  }
}

.btn-warning {
  background: var(--warning);
  border-color: var(--warning);
  color: var(--white);

  span {
    color: var(--white) !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--warning) !important;
    background: var(--warning-light);
    border-color: var(--warning-light);

    span {
      color: var(--warning) !important;
    }
  }
}

.btn-warning__light {
  background: var(--warning-light);
  border-color: var(--warning-light);
  color: var(--warning);

  span {
    color: var(--warning) !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--white) !important;
    background: var(--warning);
    border-color: var(--warning);

    span {
      color: var(--white) !important;
    }
  }
}

.btn-danger {
  background: var(--danger);
  border-color: var(--danger);
  color: var(--white);

  span {
    color: var(--white) !important;
  }

  &:hover,
  &:focus,
  &:active {
    color: var(--danger) !important;
    background: var(--danger-light);
    border-color: var(--danger-light);

    span {
      color: var(--danger) !important;
    }
  }
}
