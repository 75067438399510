@import '~bootstrap/scss/functions';
$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1200px,
  xxl: 1400px,
  xxxl: 1800px,
);
@import '~bootstrap/scss/variables';
@import '~bootstrap/scss/mixins';
@import '~bootstrap/scss/maps';
@import '~bootstrap/scss/root';
@import '~bootstrap/scss/reboot';
@import '~bootstrap/scss/grid';
@import '~bootstrap/scss/utilities';
@import '~bootstrap/scss/utilities/api';
@import '~antd/dist/antd.css';
@import '~animate.css/animate.css';
@import '~keen-slider/keen-slider.min.css';

// Common
html {
  body {
    font-family: Poppins, sans-serif;
  }
}

// Variables
@import 'variables';
@import 'common';
@import 'utils/index';
// Ant Design
@import 'antd/index';
