.notifications {
  button {
    padding: 0;
    margin: 0;
    box-shadow: none;
    border: 0;
    background: transparent;
  }

  .header {
    margin-bottom: 32px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .title {
      margin: 0;
      font-weight: 500;
      font-size: 20px;
      line-height: 32px;
      color: var(--white);
    }

    .close {
      margin-left: 12px;

      button {
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: var(--primary);

        svg {
          margin-top: -2px;
          margin-right: 4px;
        }
      }
    }
  }

  .items {
    cursor: default;

    .item {
      position: relative;
      margin-bottom: 20px;

      &:before {
        content: '';
        position: absolute;
        top: 6px;
        left: 0;
        width: 10px;
        height: 10px;
        background: var(--primary);
        border-radius: 10px;
      }

      .head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 8px;
        padding-left: 20px;

        .category {
          margin: 0;
          font-weight: 500;
          font-size: 16px;
          line-height: 24px;
          color: var(--white);
        }

        .time {
          margin-left: 12px;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          color: var(--right-sidebar-secondary);
        }
      }

      .content {
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--right-sidebar-secondary);
      }
    }
  }
}
