.pageTitle {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;

  h1 {
    margin-top: 0;
    margin-bottom: 0;
    margin-right: 12px;
    font-weight: 600;
    font-size: 40px;
    line-height: 60px;
    color: var(--gray-4);
  }

  p {
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: var(--gray-5);
    margin-bottom: 0;
  }

  h2 {
    @extend h1;
    font-size: 28px;
    line-height: 36px;
  }
}

@media screen and (max-width: 1024px) {
  .pageTitle {
    h1 {
      font-size: 28px;
      line-height: 36px;
    }
  }
}
