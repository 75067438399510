.ant-modal-wrap {
  z-index: 9999999999 !important;
}

.ant-modal-root {
  .ant-modal-content {
    background: var(--white-2);
    box-shadow: none;
    border-radius: 16px;
    position: relative;
  }

  .ant-modal-header {
    padding: 26px 32px;
    color: var(--gray-4);
    background: var(--white-2);
    border-bottom: 0;
    border-radius: 16px 16px 0 0;
    // border-bottom: 1px solid var(--border-color);
    position: relative;
  }

  .ant-modal-body {
    padding-top: 32px;

    .form-screen-title {
      margin-top: 0;
      margin-bottom: 24px;
      font-weight: 400;
      font-size: 24px;
      line-height: 36px;
      text-align: center;
      color: var(--gray-4);
    }
  }

  .ant-modal-title {
    color: var(--gray-4);
    font-weight: 600;
    font-size: 40px;
    line-height: 150%;
    text-align: center;
  }

  .ant-modal-close {
    position: absolute;
    top: 32px;
    right: 32px;
    z-index: 10;
    padding: 0;
    color: var(--primary);
    border: none;
    border-radius: 0;
    width: 48px;
    height: 48px;

    .ant-modal-close-x {
      width: 32px;
      height: 32px;
      line-height: 7px;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      font-size: 16px;

      svg {
        width: 32px;
        height: 32px;
      }
    }
  }
}

.create-campaign-modal__microtasks {
  .ant-modal-body {
    padding: 0 !important;
    overflow: hidden;
    border-radius: 0 0 16px 16px;
  }
}

.full-page-modal {
  max-width: 100%;
  border-radius: 0;

  .ant-modal-content {
    border-radius: 0;
  }

  .ant-modal-header {
    border-radius: 0;
  }

  .ant-modal-body {
    border-radius: 0;
    min-height: calc(100vh - 113px);
  }

  &__subscription {
    .ant-modal-body {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  }

  &__no-paddings {
    .ant-modal-body {
      padding: 0;
    }
  }
}
