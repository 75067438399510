.authLayout {
  position: relative;
  width: 100%;
  background: var(--gray-1);

  .layoutWrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: nowrap;
    min-height: 100vh;

    h1 {
      margin-top: 0;
      margin-bottom: 32px;
      font-weight: 600;
      font-size: 36px;
      line-height: 150%;
      color: var(--gray-4);

      span {
        color: var(--primary);
      }
    }

    .layoutForm {
      width: 100%;
      max-width: 680px;
      background: var(--gray-0);
      border-radius: 16px;
      padding: 32px;
    }
  }
}
