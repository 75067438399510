.howItWorks {
  padding: 0 32px;

  button {
    padding: 0;
    margin: 0;
    border: 0;
    background: transparent;
    display: flex;
    justify-content: flex-start;
    align-items: center;

    .icon {
      margin-right: 16px;

      svg {
        height: 68px;
        width: auto;
      }
    }

    .content {
      h3 {
        margin-top: 0;
        margin-bottom: 4px;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: var(--gray-4);
      }

      p {
        margin-bottom: 0;
        font-weight: 400;
        font-size: 14px;
        line-height: 20px;
        color: var(--gray-5);
      }
    }
  }
}
