.ant-pagination .anticon {
  vertical-align: 0;
}

.ant-pagination-disabled .ant-pagination-item-link,
.ant-pagination-disabled:hover .ant-pagination-item-link {
  color: var(--gray-4);
  border-color: var(--border-color);
  cursor: not-allowed;
}

.ant-pagination-item {
  color: var(--gray-4);
  border-color: var(--border-color);
  background: var(--gray-0);
}

.ant-pagination-item-active {
  font-weight: 500;
  background: #fff;
  border-color: var(--primary);
  background: var(--gray-0);
}

.ant-pagination-item:hover {
  border-color: var(--primary-light);
  transition: all 0.3s;
}

.ant-pagination-prev:hover .ant-pagination-item-link,
.ant-pagination-next:hover .ant-pagination-item-link {
  color: var(--primary-light);
  border-color: var(--primary-light);
}
