.subscriptions {
  .left {
    .subscriptionButton {
      padding: 32px 24px;
      border-radius: 16px;
      margin: 0 0 12px;
      box-shadow: none;
      border: 1px solid var(--border-color2);
      width: 100%;
      display: flex;
      background: transparent;
      justify-content: space-between;
      align-items: center;
      transition: all 0.3s ease-in-out;

      .name {
        font-size: 18px;
        line-height: 28px;
        font-weight: 500;
        color: var(--gray-4);
        margin-right: 12px;
      }

      .price {
        font-size: 28px;
        line-height: 28px;
        font-weight: 600;
        color: var(--primary);

        sup {
          margin-left: 6px;
          text-decoration: line-through;
          font-weight: 400;
          font-size: 18px;
          line-height: 24px;
          color: var(--gray-5);
        }

        span {
          font-size: 14px;
          line-height: 28px;
          font-weight: 400;
          color: var(--gray-5);
        }
      }
    }

    .subscriptionButtonActive {
      @extend .subscriptionButton;
      background: var(--primary);
      transition: all 0.3s ease-in-out;

      .name,
      .price,
      .price sup,
      .price span {
        color: var(--white);
      }

      .price button {
        background: #fff !important;
        color: var(--primary) !important;
        transition: all 0.3s ease-in-out;

        span {
          color: var(--primary) !important;
          transition: all 0.3s ease-in-out;
        }
      }
    }
  }

  .right {
    background: var(--gray-2);
    padding: 24px;
    border-radius: 16px;

    .muted {
      font-size: 18px;
      line-height: 28px;
      color: var(--gray-3);
    }

    .features {
      margin-bottom: 24px;

      .feature {
        font-size: 16px;
        line-height: 20px;
        color: var(--gray-4);
        margin-bottom: 12px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:last-child {
          margin-bottom: 0;
        }
      }

      .featureMuted {
        @extend .feature;
        color: var(--gray-3);
      }
    }
  }
}
