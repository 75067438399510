.howItWorks {
  padding: 24px;

  .title {
    margin-top: 0;
    margin-bottom: 38px;
    color: var(--gray-4);
    font-size: 32px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;

    span {
      color: var(--primary);
    }
  }

  .blocks {
    margin-bottom: 48px;

    .block {
      display: block;
      width: 100%;
      position: relative;
      margin-bottom: 38px;
      background: var(--gray-1);
      border-radius: 16px;
      padding: 24px;
      padding-top: 32px;

      &:first-child {
        margin-right: 48px;
      }

      &:last-child {
        margin-bottom: 0;
      }

      .number {
        position: absolute;
        top: -16px;
        left: 50%;
        transform: translateX(-50%);

        .numberWrapper {
          width: 36px;
          height: 36px;
          border-radius: 50%;
          background: var(--primary);

          span {
            color: var(--white);
            font-size: 20px;
            line-height: 1;
            font-weight: 600;
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
          }
        }
      }

      p {
        font-size: 16px;
        line-height: 24px;
        color: var(--gray-4);
        font-weight: 400;
        margin-bottom: 16px;

        strong {
          font-weight: 600;
        }

        &:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}

.howItWorksGray {
  width: 100%;
  padding: 48px;
  background: var(--gray-2);

  .blocks {
    .block {
      background: var(--gray-0);
    }
  }
}
