body {
  .card {
    position: relative;
    background: var(--gray-0);
    border-radius: 16px;
    box-shadow: none;

    &-head {
      padding: 30px 30px 0;

      .title {
        margin-top: 0;
        margin-bottom: 0;
        font-weight: 600;
        font-size: 16px;
        line-height: 20px;
        letter-spacing: 0.04em;
        color: var(--gray-4);

        a {
          margin-left: 16px;
          font-weight: 400;
          font-size: 12px;
          line-height: 20px;
          letter-spacing: 0.04em;
        }
      }
    }
  }

  .card-body__paddings {
    padding: 30px;
  }
}

@media screen and (max-width: 1640px) {
  body {
    .card {
      border-radius: 12px;

      &-head {
        padding: 16px 16px 0;
      }

      .card-body__paddings {
        padding: 16px;
      }
    }
  }
}
